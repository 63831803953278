<template lang="pug">
  .basic-plan-info
    .container-fluid
      ImmutableField(
        :title="`${i18nPrefix}.type`"
        :text="$t(`company_system.rate_plan_settings.rate_plan.details.basic_settings.${getProp('type')}`)"
      )
      ImmutableField(
        :title="`${i18nPrefix}.available_reservation_time`"
        :text="reservationTimeLabel"
      )
      ImmutableField(
        :title="`${i18nPrefix}.standard_options`"
        :text="getProp('standard_options')"
      )
      ImmutableField(
        :title="`${i18nPrefix}.selected_options`"
        :text="getProp('selected_options')"
      )
      ImmutableField(
        :title="`${i18nPrefix}.rounding_off`"
        :text="$t(`company_system.rate_plan_settings.rate_plan.details.common_settings.${roundable}`)"
      )
      ImmutableField(
        :title="`${i18nPrefix}.description`"
        :text="getProp('description')"
      )
</template>

<script>
  // misc
  import { CLOSING_OUT_TYPES } from "@/pages/CompanySystem/RatePlanSettings/RatePlan/Details/constants"
  import { get } from "lodash-es"

  export default {
    components: {
      ImmutableField: () => import("./ImmutableField"),
      FormPartial: () => import("@/components/CompanySystem/FormPartial")
    },

    props: {
      item: {
        type: Object,
        default: () => {}
      }
    },

    data() {
      return {
        ...CLOSING_OUT_TYPES
      }
    },

    methods: {
      getProp(name) {
        return get(this.item, name)
      },

      reservationTimeOptionLabel(type) {
        return this.$t(`company_system.rate_plan_settings.rate_plan.details.basic_settings.${type}`)
      },

      untilTimeLabel({ until_hour }) {
        return `${until_hour} ${this.reservationTimeOptionLabel("until_hour")}`
      },

      daysBeforeLabel({ until_hour, days_before }, type) {
        return `${days_before} ${this.reservationTimeOptionLabel(type)} ${until_hour} ${this.reservationTimeOptionLabel(
          "until_time"
        )}`
      }
    },

    computed: {
      i18nPrefix() {
        return "ota_management.plan_list.form.basic_plan_info"
      },

      reservationTime() {
        return get(this.item, "available_reservation_time", {})
      },

      reservationTimeLabel() {
        const { type, options } = this.reservationTime

        switch (type) {
          case this.shopHours:
            return this.reservationTimeOptionLabel("apply_shop_reservation_hours")
          case this.untilHour:
            return this.untilTimeLabel(options)
          case this.daysBefore:
          case this.businessDayBefore:
            return this.daysBeforeLabel(options, type)
          default:
            return ""
        }
      },

      roundable() {
        return this.getProp("roundable") ? "can_be_rounded_down" : "can_not_be_rounded_down"
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  .basic-plan-info
    +listing-container(0px)
    overflow-y: scroll

    ::v-deep
      .row
        .immutable-field
          margin-bottom: 20px

          .label
            margin-bottom: 3px
</style>
